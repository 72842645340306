import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["userMenu"]
  }

  initialize() {
    this.isShowing = false
    this.showListenerId = "mgrokd:sidebar-mobile:show"
  }

  showSidebarMobile() {
    document.dispatchEvent(new Event(this.showListenerId))
  }

  toggleUserMenu(event) {
    event.preventDefault()
    if (this.isShowing) {
      this.userMenuTarget.classList.add("hidden")
      this.isShowing = false
    } else {

      this.userMenuTarget.classList.remove("hidden")
      this.isShowing = true
    }
  }
}
